import React, { useState, useEffect } from 'react';

import './components.css';

import * as Icon from 'react-bootstrap-icons';

function ScrollToTop() {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScrollButtonVisibility = () => {
            window.pageYOffset > 100 ? setShowButton(true) : setShowButton(false);
        };
        window.addEventListener('scroll', handleScrollButtonVisibility);
        return () => {
            window.removeEventListener('scroll', handleScrollButtonVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scroll({ top: 0, behavior: 'smooth'});
    };

    return (
        showButton && (
            <div className="scrollToTop" onClick={scrollToTop}>
                <Icon.ArrowUpCircle />
            </div>
        )
    );
}

export default ScrollToTop;