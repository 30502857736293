import React from 'react';

function Services(prop) {
    return (
        <div ref={prop.servicesRef} id={prop.id} className="page common">
            <div className="section">
                <code>
                    <h3>WHAT WE DO</h3>
                    At Enewit, we specialize in consultancy services across various fields, primarily offering full-time commitments to ensure long-term customer satisfaction.

                    <h4>Our Expertise</h4>
                    <ul>
                        <li>CI/CD</li>
                        <li>DevOps</li>
                        <li>Software Development</li>
                        <li>Test Engineering</li>
                    </ul>

                    Our expertise covers a wide range of technologies, but we know the tech industry is filled with buzzwords. Let's explore some specifics:

                    <ul>
                        <li>Ansible</li>
                        <li>Azure</li>
                        <li>C#</li>
                        <li>Kubernetes</li>
                        <li>React.js and Node.js</li>
                        <li>Python</li>
                        <li>Terraform</li>
                    </ul>

                    While claiming expertise in every technology or language might seem ambitious, our priority is delivering results and maintaining an unwavering focus on solutions rather than dwelling on challenges.
                    Our commitment lies in providing dedicated attention and effective problem-solving tailored to our customers' needs.

                    <h4>Other commitments</h4>
                    Among our engagements, we collaborate with <a href="https://www.flexmassage.se/" target="_blank">Flexmassage</a>, assisting in the ongoing maintenance and development of their website.
                </code>
            </div>
        </div>
    );
}

export default Services;