
import React, { useRef } from "react";

import Container from 'react-bootstrap/Container';

import About from './about'
import Services from './services'
import Resume from './resume'
import Contact from './contact'

import ScrollToTop from './components/scrollToTop'

import logo from '../assets/images/logo.png';

import './common.css';
import './home.css';

function Home() {

    const aboutRef = useRef()
    const servicesRef = useRef()
    const resumeRef = useRef()
    const contactRef = useRef()

    const onMenuClick = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const refresh = () => window.location.reload(true)

    return (
        <Container>
            <div className="common">
                <div className="common header" onClick={refresh}>
                    <img src={logo} className="logo" alt="logo" />
                    <div className="header-enewit"><h1><code >ENEWIT</code></h1></div>
                </div>
                <div className="header-menu" onClick={() => onMenuClick("about")}><h2><code>ABOUT</code></h2></div>
                <div className="header-menu" onClick={() => onMenuClick("services")}><h2><code>WHAT WE DO</code></h2></div>
                <div className="header-menu" onClick={() => onMenuClick("resume")}><h2><code>WHO WE ARE</code></h2></div>
                <div className="header-menu" onClick={() => onMenuClick("contact")}><h2><code>CONTACT</code></h2></div>
            </div>
            <About aboutRef={aboutRef} id="about" />
            <Services servicesRef={servicesRef} id="services" />
            <Resume resumeRef={resumeRef} id="resume" />
            <Contact contactRef={contactRef} id="contact" />
            <ScrollToTop />
        </Container>
    );
}

export default Home;
