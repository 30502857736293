import React from 'react';

import * as Icon from 'react-bootstrap-icons';

import imageFE from "./../assets/images/fredrik-enewit.jpg"
import imageKJ from "./../assets/images/karl-johan-enewit.jpg"
import imageFEmin from "./../assets/images/fredrik-enewit-min.jpg"
import imageKJmin from "./../assets/images/karl-johan-enewit-min.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function Resume(prop) {

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <div ref={prop.resumeRef} id={prop.id} className="page common">
            <div className="section">
                <h3><code>WHO WE ARE</code></h3>
                <h6><code>DRY, checkout our LinkedIn profiles</code></h6>
            </div>
            <div className="presentation">
                <div class="content" onClick={() => openInNewTab('https://www.linkedin.com/in/fredrik-ellis-bb668362')}>
                    <LazyLoadImage src={imageFE}
                        PlaceholderSrc={imageFEmin}
                        width="100%" height="100%"
                        effect="blur"
                        alt="Fredrik Ellis image alt"
                    />
                    <div className='mask'></div>
                    <div className='mask-header presentation'>
                        <div>
                            <Icon.Linkedin color="#0d6efd" />
                        </div>
                        <div>
                            <h3><code>FREDRIK</code></h3>
                        </div>
                    </div>
                </div>
                <div class="content" onClick={() => openInNewTab('https://www.linkedin.com/in/karl-johan-von-hacht-7028ba153/')}>
                    <LazyLoadImage src={imageKJ}
                        PlaceholderSrc={imageKJmin}
                        width="100%" height="100%"
                        effect="blur"
                        alt="Karl Johan image alt"
                    />
                    <div className='mask'></div>
                    <div className='mask-header presentation'>
                        <div>
                            <Icon.Linkedin color="#0d6efd" />
                        </div>
                        <div>
                            <h3><code>KARL-JOHAN</code></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resume;
