import React from 'react';

import * as Icon from 'react-bootstrap-icons';

function Contact(prop) {

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <div ref={prop.contactRef} id={prop.id} className="page common">
            <div className="section" onClick={() => openInNewTab('mailto:contact@enewit.se')}>
                <h3><code>CONTACT US</code></h3>
                <Icon.EnvelopeAtFill /><code className="email">contact@enewit.se</code>
            </div>
        </div>
    );
}

export default Contact;