import React from 'react';

function About(prop) {
    return (
        <div ref={prop.aboutRef} id={prop.id} className="page common">
            <div className="section">
                <h3><code>ABOUT</code></h3>
                <code>
                    Welcome to Enewit, a small IT consultancy based in Gothenburg. We're a team of dedicated software developers with no unnecessary frills or heavy bureaucracy. Take a moment to explore and get to know us better!
                </code>
            </div>
        </div>
    );
}

export default About;